import React from 'react';
import loadable from '@loadable/component';

const Seo = loadable(() => import('../components/Seo'));
const AnimationRevealPage = loadable(() => import('../components/AnimationRevealPage'));
const SchoolHero = loadable(() => import('../components/Heros/SchoolHero'));
const SchoolFeatures = loadable(() => import('../components/Features/SchoolFeatures'));
const SchoolSteps = loadable(() => import('../components/Steps/SchoolSteps'));
const SchoolBlog = loadable(() => import('../components/Blogs/SchoolBlog'));
const SchoolEvents = loadable(() => import('../components/Events/SchoolEvents'));
const SchoolFaqs = loadable(() => import('../components/Faqs/SchoolFaqs'));
const Layout = loadable(() => import('../components/Layout'));

const IndexPage = () => (
  <AnimationRevealPage>
    <Layout>
      <Seo />
      <SchoolHero />
      <SchoolFeatures />
      <SchoolSteps />
      <SchoolBlog />
      <SchoolEvents />
      <SchoolFaqs />
    </Layout>
  </AnimationRevealPage>
);

export default IndexPage;
